import React from 'react';
import MapWithPanel from './MapWithPanel';
import { AuthProvider } from './contexts/AuthContext';
import './App.css';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <MapWithPanel />
      </AuthProvider>
    </div>
  );
}

export default App;