import React, { createContext, useState, useEffect, useContext } from 'react';
import { authAPI } from '../services/api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Check if user is logged in on component mount
    const checkLoginStatus = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          try {
            // Fetch the user profile
            const response = await authAPI.getCurrentUser();
            setCurrentUser(response.data);
          } catch (userError) {
            console.error('Error fetching user details:', userError);
            // Token might be invalid
            localStorage.removeItem('token');
          }
        }
      } catch (err) {
        console.error('Failed to get current user:', err);
        // If token is invalid, remove it
        localStorage.removeItem('token');
      } finally {
        setLoading(false);
      }
    };

    checkLoginStatus();
  }, []);

  const login = async (email, password) => {
    try {
      setError(null);
      const data = await authAPI.login(email, password);
      
      console.log('Login data in context:', data);
      
      if (data.access_token) {
        try {
          // Fetch the user profile
          const userResponse = await authAPI.getCurrentUser();
          console.log('User response:', userResponse);
          setCurrentUser(userResponse.data);
          return true;
        } catch (userError) {
          console.error('Error fetching user details:', userError);
          // If we can't get the user profile but have a token,
          // create a basic user object
          setCurrentUser({ 
            email: email,
            is_authenticated: true
          });
          return true;
        }
      }
      
      // If we get here, login failed despite no errors
      setError('Invalid login response');
      return false;
    } catch (err) {
      console.error('Login error in context:', err);
      
      // Properly handle error objects and convert them to strings
      if (err.response?.data?.detail) {
        // Handle FastAPI validation error which could be an object
        const detail = err.response.data.detail;
        if (typeof detail === 'object') {
          // If it's a validation error object, format it nicely
          setError(`Validation error: ${JSON.stringify(detail)}`);
        } else {
          // If it's already a string, use it directly
          setError(detail);
        }
      } else if (err.message) {
        // If there's an error message, use it
        setError(`Login failed: ${err.message}`);
      } else {
        // Generic error message
        setError('Failed to login');
      }
      return false;
    }
  };

  const register = async (email, password) => {
    try {
      setError(null);
      await authAPI.register(email, password);
      // Automatically log in after registration
      return login(email, password);
    } catch (err) {
      // Properly handle error objects and convert them to strings
      if (err.response?.data?.detail) {
        // Handle FastAPI validation error which could be an object
        const detail = err.response.data.detail;
        if (typeof detail === 'object') {
          // If it's a validation error object, format it nicely
          setError(`Validation error: ${JSON.stringify(detail)}`);
        } else {
          // If it's already a string, use it directly
          setError(detail);
        }
      } else {
        // Generic error message
        setError('Failed to register');
      }
      return false;
    }
  };

  const logout = () => {
    authAPI.logout();
    setCurrentUser(null);
  };

  const value = {
    currentUser,
    loading,
    error,
    login,
    register,
    logout,
    isAuthenticated: !!currentUser
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthContext;