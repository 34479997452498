import React from 'react';
import { useAuth } from '../contexts/AuthContext';

const Navigation = ({ activePage, setActivePage, togglePanel }) => {
  const { currentUser, logout, isAuthenticated } = useAuth();

  const handleLogout = () => {
    logout();
    if (activePage === 'scanner' || activePage === 'targets') {
      setActivePage('map');
    }
  };

  const handleLoginClick = () => {
    togglePanel('login');
  };

  return (
    <div className="navigation">
      <div className="nav-brand">
        <button onClick={() => togglePanel('menu')} className="menu-button">
          <svg className="icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 -2 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M5 7h14M5 12h14M5 17h14"/>
          </svg>
        </button>
        <h3>TriNater <span style={{ fontSize: '14px', opacity: 0.7 }}>Fire Detection System</span></h3>
      </div>
      
      <div className="nav-actions">
        <div className="nav-tabs">
          <button 
            className={`nav-tab ${activePage === 'map' ? 'active' : ''}`} 
            onClick={() => {
              setActivePage('map');
              togglePanel('menu');
            }}
          >
            Map
          </button>
          
          {isAuthenticated && (
            <>
              <button 
                className={`nav-tab ${activePage === 'scanner' ? 'active' : ''}`} 
                onClick={() => {
                  setActivePage('scanner');
                  togglePanel('menu');
                }}
              >
                Scanner
              </button>
              
              <button 
                className={`nav-tab ${activePage === 'targets' ? 'active' : ''}`} 
                onClick={() => {
                  setActivePage('targets'); 
                  togglePanel('menu');
                }}
              >
                Targets
              </button>
            </>
          )}
        </div>
        
        <div className="nav-user">
          {isAuthenticated ? (
            <>
              <span className="user-email">{currentUser?.email}</span>
              <button className="logout-button" onClick={handleLogout}>Logout</button>
            </>
          ) : (
            <button className="login-button" onClick={handleLoginClick}>Login</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navigation;