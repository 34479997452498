import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polygon } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './MapWithPanel.css';
import './components/components.css';
// Import components
import Login from './components/Login';
import Navigation from './components/Navigation';
import SideMenu from './components/SideMenu';
import WindLayer from './components/WindLayer';
// No need to import NotificationCenter here since it's used in SideMenu

// Import API services
import { fireAPI, weatherAPI, notificationAPI } from './services/api';

// Fix for Leaflet marker icons - This is a known Leaflet issue with webpack
// Leaflet's default icon references assets that might not be available in your build
import icon from './marker-icon.png';
import flameIcon from './flame.png';
import iconShadow from './marker-shadow.png';

// Create a default icon
const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

// Create a fire icon - a red marker for fire locations
const FireIcon = L.icon({
  iconUrl: flameIcon,
  shadowUrl: iconShadow,
  iconSize: [32, 32], // Adjusted size for flame icon
  iconAnchor: [16, 32], // Center bottom of the icon
  popupAnchor: [0, -32], // Center above the icon
  className: 'fire-marker' // This will be used to add a CSS class to style the marker
});

// Set default icon for all markers
L.Marker.prototype.options.icon = DefaultIcon;



export const MapWithPanel = () => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [panelContent, setPanelContent] = useState(null);
  const [selectedLayer, setSelectedLayer] = useState("none");
  const [activePage, setActivePage] = useState('map');
  const [markers, setMarkers] = useState([]);
  const [perimeters, setPerimeters] = useState([]);
  // State for selected fire data (used by notification center)
  const [selectedFireData, setSelectedFireData] = useState(null);

  // Auto-open panel and set to menu when any page is selected
  React.useEffect(() => {
    // Always open the panel with menu content when page changes
    setIsPanelOpen(true);
    setPanelContent('menu');
  }, [activePage]);

  const togglePanel = (contentType) => {
    setPanelContent(contentType);
    setIsPanelOpen(!isPanelOpen);
  };

  const closePanel = () => {
    setIsPanelOpen(false);
  };

  const handleLayerChange = (event) => {
    setSelectedLayer(event.target.value);
  };
  
  const handleAddMarkers = (newMarkers) => {
    // Add new markers to the map
    console.log('handleAddMarkers called with:', newMarkers);
    if (!Array.isArray(newMarkers) || newMarkers.length === 0) {
      console.warn('Invalid markers received:', newMarkers);
      return;
    }
    
    // Force render of the markers by using a completely new array
    console.log('Adding markers to the map. Count:', newMarkers.length);
    console.log('First marker:', newMarkers[0]);
    
    // Using functional update to avoid race conditions
    setMarkers(prevMarkers => {
      console.log('Previous markers:', prevMarkers);
      // Create a new array to ensure React detects the change
      const updatedMarkers = [...prevMarkers, ...newMarkers];
      console.log('Updated markers:', updatedMarkers);
      return updatedMarkers;
    });
    
    // Log after the state update is scheduled
    console.log('Markers should be updated now');
  };
  
  const handleClearMarkers = () => {
    // Clear all markers from the map
    console.log('Clearing all markers');
    setMarkers([]);
    setPerimeters([]);
  };
  
  const fetchWeatherDataForFire = async (marker) => {
    try {
      // Get current time or marker's detection time if available
      const timestamp = marker.detected_at || new Date().toISOString();
      
      // Fetch weather data for the fire location
      const response = await notificationAPI.getFireWeatherData(
        marker.lat, 
        marker.lng, 
        timestamp
      );
      
      // Return enhanced marker with weather data
      return {
        ...marker,
        weather: response.data
      };
    } catch (error) {
      console.error("Failed to fetch weather data:", error);
      // Return original marker if weather data fetch fails
      return {
        ...marker,
        weather: {
          wind_speed: 15, // Default wind speed in km/h
          wind_direction: 90, // Default wind direction in degrees (East)
          temperature: 25, // Default temperature in Celsius
          humidity: 30 // Default humidity percentage
        }
      };
    }
  };
  
  const handleOpenNotificationCenter = async (marker) => {
    // Fetch weather data for the marker
    const enhancedMarker = await fetchWeatherDataForFire(marker);
    
    // Set the selected fire data with weather information
    setSelectedFireData(enhancedMarker);
    
    // Make sure the panel is open
    setIsPanelOpen(true);
    
    // Set active page to notifications
    setActivePage('notifications');
    
    // Set panel content to menu (which controls the tabs)
    setPanelContent('menu');
  };
  
  const handleAddPerimeters = (newPerimeters) => {
    // Add new perimeters to the map
    console.log('handleAddPerimeters called with:', newPerimeters);
    if (!Array.isArray(newPerimeters) || newPerimeters.length === 0) {
      console.warn('Invalid perimeters received:', newPerimeters);
      return;
    }
    
    // Force render of the perimeters by using a completely new array
    console.log('Adding perimeters to the map. Count:', newPerimeters.length);
    
    // Using functional update to avoid race conditions
    setPerimeters(prevPerimeters => {
      console.log('Previous perimeters:', prevPerimeters);
      // Create a new array to ensure React detects the change
      const updatedPerimeters = [...prevPerimeters, ...newPerimeters];
      console.log('Updated perimeters:', updatedPerimeters);
      return updatedPerimeters;
    });
    
    console.log('Perimeters should be updated now');
  };

  const renderPanelContent = () => {
    // If panel is opened for specific content, show that
    if (panelContent) {
      switch (panelContent) {
        case 'login':
          return <Login onClose={closePanel} />;
        case 'menu':
          return (
            <SideMenu
              activePage={activePage}
              setActivePage={setActivePage}
              closePanel={closePanel}
              selectedLayer={selectedLayer}
              handleLayerChange={handleLayerChange}
              onAddMarkers={handleAddMarkers}
              onAddPerimeters={handleAddPerimeters}
              selectedFireData={selectedFireData}
            />
          );
        case 'gps':
          return (
            <div className="panel-content">
              <h3>Go to Location</h3>
              <input type="text" placeholder="Latitude" />
              <input type="text" placeholder="Longitude" />
              <button>Go</button>
            </div>
          );
        case 'layers':
          return (
            <div className="panel-content">
              <h3>Layers</h3>
              <label>
                <input
                  type="radio"
                  name="layer"
                  value="none"
                  onChange={handleLayerChange}
                  checked={selectedLayer === "none"}
                />
                None
              </label><br/>
              {/* <label>
                <input 
                  type="radio" 
                  name="layer" 
                  value="ActiveFires"
                  onChange={handleLayerChange}
                  checked={selectedLayer === "ActiveFires"}
                />
                Active Fires
              </label><br/>
              <label>
                <input 
                  type="radio" 
                  name="layer" 
                  value="PredictedRiskQuotient" 
                  onChange={handleLayerChange}
                  checked={selectedLayer === "PredictedRiskQuotient"}
                />
                Predicted Risk Quotient
              </label><br/> */}
              <label>
                <input 
                  type="radio" 
                  name="layer" 
                  value="Weather" 
                  onChange={handleLayerChange}
                  checked={selectedLayer === "Weather"}
                />
                Weather
              </label><br/>
              <label>
                <input 
                  type="radio" 
                  name="layer" 
                  value="Wind" 
                  onChange={handleLayerChange}
                  checked={selectedLayer === "Wind"}
                />
                Wind
              </label><br/>
              <label>
                <input 
                  type="radio" 
                  name="layer" 
                  value="Moisture" 
                  onChange={handleLayerChange}
                  checked={selectedLayer === "Moisture"}
                />
                Moisture
              </label><br/>
              <label>
                <input 
                  type="radio" 
                  name="layer" 
                  value="PopulationDensity" 
                  onChange={handleLayerChange}
                  checked={selectedLayer === "PopulationDensity"}
                />
                Population Density
              </label><br/>
            </div>
          );
        default:
          return <div>Select an option</div>;
      }
    }
    
    // Otherwise, show content based on the active page
    return <div>Select an option from the tabs above</div>;
  };

  // Standard map tiles
  const baseLayerUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  // Dark-themed map tiles option
  // const baseLayerUrl = "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png";

  // Render the map container - always visible regardless of active page
  const renderMapContainer = () => {
    return (
      <div className="map-container">
        <div className="floating-buttons" style={{ right: isPanelOpen ? '320px' : '20px' }}>
          <button onClick={() => togglePanel('menu')} className="layers-button">
            <svg className="icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path fillRule="evenodd" d="M5.005 10.19a1 1 0 0 1 1 1v.233l5.998 3.464L18 11.423v-.232a1 1 0 1 1 2 0V12a1 1 0 0 1-.5.866l-6.997 4.042a1 1 0 0 1-1 0l-6.998-4.042a1 1 0 0 1-.5-.866v-.81a1 1 0 0 1 1-1ZM5 15.15a1 1 0 0 1 1 1v.232l5.997 3.464 5.998-3.464v-.232a1 1 0 1 1 2 0v.81a1 1 0 0 1-.5.865l-6.998 4.042a1 1 0 0 1-1 0L4.5 17.824a1 1 0 0 1-.5-.866v-.81a1 1 0 0 1 1-1Z" clipRule="evenodd"/>
              <path d="M12.503 2.134a1 1 0 0 0-1 0L4.501 6.17A1 1 0 0 0 4.5 7.902l7.002 4.047a1 1 0 0 0 1 0l6.998-4.04a1 1 0 0 0 0-1.732l-6.997-4.042Z"/>
            </svg>
          </button>
          <button onClick={() => togglePanel('gps')} className="maps-button">
            <svg className="icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.8 13.938h-.011a7 7 0 1 0-11.464.144h-.016l.14.171c.1.127.2.251.3.371L12 21l5.13-6.248c.194-.209.374-.429.54-.659l.13-.155Z"/>
            </svg>
          </button>
        </div>

        <MapContainer center={[45.5152, -122.6784]} zoom={5} style={{ height: '100%', width: '100%' }}>
          {/* Base map layer - always visible */}
          <TileLayer
            url={baseLayerUrl}
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          
          
          {/* Weather layer */}
          {selectedLayer === "Weather" && (
            <TileLayer
              url={weatherAPI.getCloudLayerUrl()}
              attribution='&copy; <a href="https://openweathermap.org">OpenWeatherMap</a> contributors'
            />
          )}
          
          {/* Wind layer */}
          {selectedLayer === "Wind" && (
            <>
              {/* We keep the tile layer for compatibility but the actual visualization comes from WindLayer */}
              <TileLayer
                url={weatherAPI.getWindLayerUrl()}
                opacity={0.3}
                attribution='&copy; <a href="https://openweathermap.org">OpenWeatherMap</a> contributors'
              />
              <WindLayer active={true} />
            </>
          )}
          
          {/* Moisture layer */}
          {selectedLayer === "Moisture" && (
            <TileLayer
              url={weatherAPI.getPrecipitationLayerUrl()}
              attribution='&copy; <a href="https://openweathermap.org">OpenWeatherMap</a> contributors'
            />
          )}
          {/* Active Fire layer */}
          {selectedLayer === "ActiveFires" && (
            <TileLayer
              url={fireAPI.getActiveFiresUrl()}
              attribution='&copy; <a href="">NASA</a> contributors'
            />
          )}
          
          {/* Render perimeters for fire areas */}
          {console.log('Current perimeters state:', perimeters)}
          
          {Array.isArray(perimeters) && perimeters.map((perimeter, index) => {
            if (!perimeter || !Array.isArray(perimeter.coordinates) || perimeter.coordinates.length < 3) {
              console.warn(`Invalid perimeter at index ${index}:`, perimeter);
              return null;
            }
            
            console.log(`Rendering perimeter ${index} with ${perimeter.coordinates.length} points`);
            
            // Base color is maroon/dark red, opacity increases with confidence
            const baseColor = '#800000'; // Maroon
            const fillOpacity = perimeter.confidence ? 
              Math.max(0.1, Math.min(0.4, perimeter.confidence * 0.4)) : 0.2;
            
            return (
              <Polygon
                key={`perimeter-${index}-${Date.now()}`}
                positions={perimeter.coordinates}
                pathOptions={{
                  color: baseColor,
                  weight: 2,
                  fillColor: baseColor,
                  fillOpacity: fillOpacity,
                  opacity: 0.7
                }}
              />
            );
          })}
          
          {/* Render markers for detected fires */}
          {console.log('Current markers state:', markers)}
          
          {Array.isArray(markers) && markers.map((marker, index) => {
            if (!marker || typeof marker.lat !== 'number' || typeof marker.lng !== 'number') {
              console.warn(`Invalid marker at index ${index}:`, marker);
              return null;
            }
            
            console.log(`Rendering marker ${index} at [${marker.lat}, ${marker.lng}]`);
            
            return (
              <Marker 
                key={`marker-${index}-${Date.now()}`}
                position={[marker.lat, marker.lng]}
                icon={FireIcon}
                zIndexOffset={1000} // Make fire markers always appear on top
              >
                <Popup>
                  <div style={{ padding: '5px' }}>
                    <h4 style={{ margin: '0 0 8px 0', color: '#d32f2f' }}>Fire Location #{marker.id || index + 1}</h4>
                    <p style={{ margin: '5px 0' }}><strong>Latitude:</strong> {marker.lat.toFixed(5)}</p>
                    <p style={{ margin: '5px 0' }}><strong>Longitude:</strong> {marker.lng.toFixed(5)}</p>
                    {marker.confidence && 
                      <p style={{ margin: '5px 0' }}>
                        <strong>Confidence:</strong> 
                        <span style={{ 
                          color: marker.confidence > 0.85 ? '#d32f2f' : 
                                marker.confidence > 0.7 ? '#ff9800' : '#4caf50' 
                        }}>
                          {(marker.confidence * 100).toFixed(1)}%
                        </span>
                      </p>
                    }
                    
                    {marker.weather && (
                      <div style={{ margin: '10px 0 5px 0', padding: '5px 0', borderTop: '1px solid #eee' }}>
                        <p style={{ margin: '5px 0' }}>
                          <strong>Est. Fire Growth Rate:</strong>{' '}
                          <span style={{ 
                            color: marker.weather.wind_speed > 20 ? '#d32f2f' : 
                                  marker.weather.wind_speed > 10 ? '#ff9800' : '#4caf50',
                            fontWeight: 'bold'
                          }}>
                            {marker.weather.wind_speed > 20 ? 'High' : 
                             marker.weather.wind_speed > 10 ? 'Medium' : 'Low'}
                          </span>
                        </p>
                        <p style={{ margin: '5px 0' }}><strong>Wind Speed:</strong> {marker.weather.wind_speed} km/h</p>
                      </div>
                    )}
                    
                    <button 
                      onClick={() => handleOpenNotificationCenter(marker)}
                      style={{
                        backgroundColor: '#d32f2f',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        padding: '8px 12px',
                        cursor: 'pointer',
                        width: '100%',
                        marginTop: '10px',
                        fontWeight: 'bold'
                      }}
                    >
                      Alert & Notify
                    </button>
                    
                    <p style={{ fontSize: '0.8em', marginTop: '10px', color: '#777' }}>
                      Click anywhere to close this popup
                    </p>
                  </div>
                </Popup>
              </Marker>
            );
          })}
          
          
          {/* Clear markers button */}
          {markers.length > 0 && (
            <div className="clear-markers-button"
              style={{
                position: 'absolute',
                bottom: '20px',
                right: '20px',
                zIndex: 1000,
                backgroundColor: 'rgba(211, 47, 47, 0.9)',
                color: 'white',
                padding: '8px 15px',
                borderRadius: '4px',
                fontWeight: 'bold',
                cursor: 'pointer',
                boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
                fontSize: '14px'
              }}
              onClick={handleClearMarkers}
            >
              Clear Fire Markers ({markers.length})
            </div>
          )}
        </MapContainer>
      </div>
    );
  };

  // Main content rendering function for the active page
  const renderActivePage = () => {
    // Always render the map container regardless of the active page
    return renderMapContainer();
  };

  return (
    <div>
      <Navigation 
        activePage={activePage} 
        setActivePage={setActivePage} 
        togglePanel={togglePanel} 
      />
      
      <div className="app-container">
        <button onClick={() => togglePanel('menu')} className="pullTab">
          <svg className="icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 12h12m-12 0 4 4m-4-4 4-4M15 4h2a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-2"/>
          </svg>
        </button>

        <div className={`side-panel ${isPanelOpen ? 'open' : 'closed'}`}>
          <div id="panel-content">
            <button onClick={() => setIsPanelOpen(false)} className="close-button">
              X
            </button>
            {isPanelOpen && renderPanelContent()}
          </div>
        </div>

        <div className="content-area">
          {renderMapContainer()}
        </div>
      </div>
    </div>
  );
};

export default MapWithPanel;
