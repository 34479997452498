import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import ScannerControls from './ScannerControls';
import NotificationCenter from './NotificationCenter';

const SideMenu = ({ activePage, setActivePage, closePanel, selectedLayer, handleLayerChange, onAddMarkers, onAddPerimeters, selectedFireData }) => {
  const { isAuthenticated } = useAuth();

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  // Render Map View tab content
  const renderMapViewContent = () => {
    return (
      <div className="tab-content">
        <div className="menu-section">
          <h4>Map Layers</h4>
          <ul className="layer-options">
            <li>
              <label>
                <input
                  type="radio"
                  name="layer"
                  value="none"
                  onChange={handleLayerChange}
                  checked={selectedLayer === "none"}
                />
                None
              </label>
            </li>
            {/* <li>
              <label>
                <input 
                  type="radio" 
                  name="layer" 
                  value="ActiveFires" 
                  onChange={handleLayerChange}
                  checked={selectedLayer === "ActiveFires"}
                />
                Active Fires
              </label>
            </li>
            <li>
              <label>
                <input 
                  type="radio" 
                  name="layer" 
                  value="PredictedRiskQuotient" 
                  onChange={handleLayerChange}
                  checked={selectedLayer === "PredictedRiskQuotient"}
                />
                Predicted Risk Quotient
              </label>
            </li> */}
            <li>
              <label>
                <input 
                  type="radio" 
                  name="layer" 
                  value="Weather" 
                  onChange={handleLayerChange}
                  checked={selectedLayer === "Weather"}
                />
                Weather
              </label>
            </li>
            <li>
              <label>
                <input 
                  type="radio" 
                  name="layer" 
                  value="Wind" 
                  onChange={handleLayerChange}
                  checked={selectedLayer === "Wind"}
                />
                Wind
              </label>
            </li>
            <li>
              <label>
                <input 
                  type="radio" 
                  name="layer" 
                  value="Moisture" 
                  onChange={handleLayerChange}
                  checked={selectedLayer === "Moisture"}
                />
                Moisture
              </label>
            </li>
            {/* <li>
              <label>
                <input 
                  type="radio" 
                  name="layer" 
                  value="PopulationDensity" 
                  onChange={handleLayerChange}
                  checked={selectedLayer === "PopulationDensity"}
                />
                Population Density
              </label>
            </li> */}
          </ul>
        </div>
        
        <div className="menu-section">
          <h4>Location</h4>
          <div className="location-form">
            <input type="text" placeholder="Latitude" />
            <input type="text" placeholder="Longitude" />
            <button>Go</button>
          </div>
        </div>
      </div>
    );
  };
  
  // Render Scanner tab content
  const renderScannerContent = () => {
    return (
      <div className="tab-content">
        <div className="menu-section">
          <ScannerControls onAddMarkers={onAddMarkers} onAddPerimeters={onAddPerimeters} />
          <h4>URL Scanner Settings</h4>
          {/* <div className="scanner-form">
            <label>Scan Interval (seconds)</label>
            <input type="number" min="5" placeholder="10" />
            
            <label>URL to Scan</label>
            <input type="url" placeholder="https://example.com/data.json" />
            <label>Location to scan</label>
            <input placeholder="[[47.966461017073314, -124.28956657078744],[46.15177698853681, -115.64566246825329], [32.5701428063953, -107.99629909502727], [32.78787082689376, -123.99714132018126]]" />
            <label>Start Time</label>
            <input placeholder="2023-08-05T16:20:34" />
            <label>End Time</label>
            <input placeholder="2023-09-10T16:20:34" />
            
            <div className="button-group">
              <button className="primary-button">Start Scanning</button>
              <button className="secondary-button">Stop Scanning</button>
            </div>
          </div> */}
        </div>
        
        {/* <div className="menu-section">
          <h4>Scan Status</h4>
          <div className="status-box">
            <p><strong>Status:</strong> Idle</p>
            <p><strong>Last Scan:</strong> N/A</p>
            <p><strong>Targets Found:</strong> 0</p>
          </div>
        </div> */}
      </div>
    );
  };
  
  // Render Targets tab content
  const renderTargetsContent = () => {
    return (
      <div className="tab-content">
        <div className="menu-section">
          <h4>Target Filters</h4>
          <div className="filter-form">
            <label>
              <input type="checkbox" defaultChecked />
              Active Fires
            </label>
            <label>
              <input type="checkbox" defaultChecked />
              Predicted Fires
            </label>
            
            <label>Confidence Level</label>
            <select>
              <option>All</option>
              <option>High (75%+)</option>
              <option>Medium (50-75%)</option>
              <option>Low (Below 50%)</option>
            </select>
            
            <label>Time Range</label>
            <select>
              <option>Last 24 Hours</option>
              <option>Last Week</option>
              <option>Last Month</option>
              <option>All Time</option>
            </select>
          </div>
        </div>
        
        <div className="menu-section">
          <h4>Target List</h4>
          <div className="target-list">
            <p>No targets found matching current filters.</p>
          </div>
        </div>
      </div>
    );
  };
  
  // Render Notifications tab content
  const renderNotificationsContent = () => {
    return (
      <div className="tab-content">
        {selectedFireData ? (
          <NotificationCenter 
            fireData={selectedFireData} 
            onClose={closePanel} 
          />
        ) : (
          <div className="menu-section">
            <h4>Notification Center</h4>
            <p>No fire selected. Click on a fire marker on the map to view notification options.</p>
          </div>
        )}
      </div>
    );
  };

  // Render the appropriate tab content based on active page
  const renderActiveTabContent = () => {
    switch (activePage) {
      case 'map':
        return renderMapViewContent();
      case 'scanner':
        return renderScannerContent();
      case 'targets':
        return renderTargetsContent();
      case 'notifications':
        return renderNotificationsContent();
      default:
        return renderMapViewContent();
    }
  };

  return (
    <div className="side-menu">
      <div className="tabs-container">
        <div className="tabs">
          <button 
            className={`tab ${activePage === 'map' ? 'active' : ''}`}
            onClick={() => handlePageChange('map')}
          >
            Map View
          </button>
          
          {isAuthenticated && (
            <>
              <button 
                className={`tab ${activePage === 'scanner' ? 'active' : ''}`}
                onClick={() => handlePageChange('scanner')}
              >
                Scanner
              </button>
              <button 
                className={`tab ${activePage === 'targets' ? 'active' : ''}`}
                onClick={() => handlePageChange('targets')}
              >
                Targets
              </button>
              <button 
                className={`tab ${activePage === 'notifications' ? 'active' : ''}`}
                onClick={() => handlePageChange('notifications')}
              >
                Notifications
              </button>
            </>
          )}
        </div>
        
        {renderActiveTabContent()}
      </div>
    </div>
  );
};

export default SideMenu;