import axios from 'axios';

// Base URL for all API calls - centralized configuration
export const API_BASE_URL = 'https://trntr-dvhwpl3fqa-uw.a.run.app';

// Create axios instance with base URL
const api = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true // Important for CORS with credentials
});

// Authentication interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Authentication API functions
export const authAPI = {
  login: async (email, password) => {
    try {
      // FastAPI's OAuth2 expects form data with application/x-www-form-urlencoded content type
      const params = new URLSearchParams();
      params.append('username', email); // FastAPI uses 'username' for OAuth2
      params.append('password', password);
      
      console.log('Attempting login with:', { email });
      
      // Don't use withCredentials for token endpoint
      const response = await axios.post(`${API_BASE_URL}/auth/token`, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      
      console.log('Login response:', response.data);
      
      if (response.data.access_token) {
        localStorage.setItem('token', response.data.access_token);
      }
      return response.data;
    } catch (error) {
      console.error('Login error:', error.response?.data || error.message);
      // Log detailed error information for debugging
      if (error.response) {
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
        console.error('Error data:', error.response.data);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error during request setup:', error.message);
      }
      throw error;
    }
  },
  
  register: async (email, password) => {
    return api.post('/auth/register', { email, password });
  },
  
  getCurrentUser: async () => {
    try {
      const response = await api.get('/auth/users/me');
      return response;
    } catch (error) {
      console.error('Error fetching current user:', error);
      if (error.response && error.response.status === 401) {
        // Token is invalid, clear it
        localStorage.removeItem('token');
      }
      throw error;
    }
  },
  
  logout: () => {
    localStorage.removeItem('token');
  },
  
  isAuthenticated: () => {
    return localStorage.getItem('token') !== null;
  }
};

// Scanner API functions - all require authentication
export const scannerAPI = {
  registerUrl: async (scanUrl) => {
    return api.post(`/api/scan/register?scan_url=${encodeURIComponent(scanUrl)}`);
  },
  
  getStatus: async (payload = null) => {
    if (payload) {
      return api.post('/api/scan/status', payload);
    }
    return api.get('/api/scan/status');
  },
  
  getResults: async () => {
    return api.get('/api/scan/results');
  },
  
  startScanning: async () => {
    return api.post('/api/scan/start');
  },
  
  stopScanning: async () => {
    return api.post('/api/scan/stop');
  },
  
  setScanInterval: async (intervalSeconds) => {
    return api.post(`/api/scan/interval?interval_seconds=${intervalSeconds}`);
  }
};

// Target API functions - all require authentication
export const targetAPI = {
  getAllTargets: async () => {
    return api.get('/api/targets');
  },
  
  getTarget: async (targetId) => {
    return api.get(`/api/targets/${targetId}`);
  },
  
  getNotification: async (targetId, notificationNum) => {
    return api.get(`/api/targets/${targetId}/notifications/${notificationNum}`);
  }
};

// Weather API endpoints - these use our backend proxies to keep API keys secure
// The {z}/{x}/{y} are placeholders for Leaflet's TileLayer and must be in the URL as is
export const weatherAPI = {
  getWindLayerUrl: () => `${API_BASE_URL}/api/weather/wind/{z}/{x}/{y}`,
  getTempLayerUrl: () => `${API_BASE_URL}/api/weather/temp/{z}/{x}/{y}`,
  getPrecipitationLayerUrl: () => `${API_BASE_URL}/api/weather/precipitation/{z}/{x}/{y}`,
  getCloudLayerUrl: () => `${API_BASE_URL}/api/weather/clouds/{z}/{x}/{y}`,
  getPressureLayerUrl: () => `${API_BASE_URL}/api/weather/pressure/{z}/{x}/{y}`
};

// Fire monitoring API endpoints
export const fireAPI = {
  // Active fires GeoJSON data - typically from NASA FIRMS or similar service
  getActiveFiresUrl: () => `${API_BASE_URL}/api/fires/active`,
  
  // Predicted risk quotient - fire prediction model output
  getPredictedRiskUrl: () => `${API_BASE_URL}/api/fires/risk-prediction`,
  
  // Population density data
  getPopulationDensityUrl: () => `${API_BASE_URL}/api/data/population-density`
};

// Notification API functions
export const notificationAPI = {
  // Send WhatsApp notifications to list of phone numbers
  sendWhatsAppNotification: async (phoneNumbers, message, fireData) => {
    return api.post('/api/notifications/whatsapp', {
      phone_numbers: phoneNumbers,
      message,
      fire_data: fireData
    });
  },
  
  // Send Everbridge emergency notifications
  sendEverbridgeNotification: async (message, fireData) => {
    return api.post('/api/notifications/everbridge', {
      message,
      fire_data: fireData
    });
  },
  
  // Get weather data at a specific location
  getFireWeatherData: async (lat, lng, timestamp) => {
    return api.get(`/api/weather/location?lat=${lat}&lng=${lng}&time=${timestamp}`);
  }
};

export default api;
