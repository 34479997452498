import React, { useState, useEffect } from 'react';
import { scannerAPI } from '../services/api';
import { useAuth } from '../contexts/AuthContext';

const ScannerControls = ({ onAddMarkers, onAddPerimeters }) => {
  const [scanUrl, setScanUrl] = useState('');
  const [interval, setInterval] = useState(60);
  const [coordsStr, setCoordsStr] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { isAuthenticated } = useAuth();

  // Fetch status periodically if authenticated
  useEffect(() => {
    if (!isAuthenticated) return;

    const fetchStatus = async () => {
      try {
        const response = await scannerAPI.getStatus();
        setStatus(response.data);
        if (response.data.scan_url) {
          setScanUrl(response.data.scan_url);
        }
        if (response.data.scan_interval) {
          setInterval(response.data.scan_interval);
        }
      } catch (err) {
        console.error('Error fetching scan status:', err);
        setError('Failed to fetch scan status');
      }
    };

    fetchStatus();
    const timer = setInterval(fetchStatus, 5000); // Update every 5 seconds

    return () => clearInterval(timer);
  }, [isAuthenticated]);

  const handleRegisterUrl = async (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      setError('You must be logged in to use this feature');
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      await scannerAPI.registerUrl(scanUrl);
      const response = await scannerAPI.getStatus();
      setStatus(response.data);
    } catch (err) {
      console.error('Error registering URL:', err);
      setError('Failed to register URL for scanning');
    } finally {
      setLoading(false);
    }
  };

  const handleSetInterval = async (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      setError('You must be logged in to use this feature');
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      await scannerAPI.setScanInterval(interval);
      const response = await scannerAPI.getStatus();
      setStatus(response.data);
    } catch (err) {
      console.error('Error setting interval:', err);
      setError('Failed to set scan interval');
    } finally {
      setLoading(false);
    }
  };

  const handleStartScan = async () => {
    if (!isAuthenticated) {
      setError('You must be logged in to use this feature');
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      await scannerAPI.startScanning();
      const response = await scannerAPI.getStatus();
      setStatus(response.data);
    } catch (err) {
      console.error('Error starting scan:', err);
      setError('Failed to start scanning');
    } finally {
      setLoading(false);
    }
  };

  const handleStopScan = async () => {
    if (!isAuthenticated) {
      setError('You must be logged in to use this feature');
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      await scannerAPI.stopScanning();
      const response = await scannerAPI.getStatus();
      setStatus(response.data);
    } catch (err) {
      console.error('Error stopping scan:', err);
      setError('Failed to stop scanning');
    } finally {
      setLoading(false);
    }
  };
  
  const handleSimulation = async (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      setError('You must be logged in to use this feature');
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      // Parse the coordinates string into a JSON array
      let coordinates;
      try {
        coordinates = JSON.parse(coordsStr);
        if (!Array.isArray(coordinates)) {
          throw new Error('Coordinates must be an array');
        }
      } catch (parseErr) {
        console.error('Error parsing coordinates:', parseErr);
        setError('Invalid coordinates format. Please provide a valid JSON array.');
        setLoading(false);
        return;
      }
      
      // Create a test target ID and notification number
      const targetId = Math.floor(Math.random() * 1000);
      const notificationNum = 1;
      
      // Create a request payload in the format defined in the backend/format file
      const payload = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              name: `T${targetId}/N${notificationNum}`,
              start_time: startTime,
              end_time: endTime
            },
            geometry: {
              coordinates: [coordinates],
              type: "Polygon"
            },
            id: 0
          }
        ]
      };
      
      // Send the request to the API
      const response = await scannerAPI.getStatus(payload);
      setStatus(response.data);
      
      // Debug log entire response
      console.log('Simulation response:', response.data);
      
      // Add markers to the map if fire locations exist in the response
      let fireLocations = [];
      
      console.log('Response data structure:', JSON.stringify(response.data, null, 2));
      
      // Try ALL possible locations where fire_locations could be in the response
      
      // Check for nested structure first (targets → targetId → notificationNum → detection_results → fire_locations)
      if (response.data.targets && 
          response.data.targets[targetId] && 
          response.data.targets[targetId][notificationNum] && 
          response.data.targets[targetId][notificationNum].detection_results &&
          response.data.targets[targetId][notificationNum].detection_results.fire_locations) {
        
        console.log('Found fire locations in targets structure');
        fireLocations = response.data.targets[targetId][notificationNum].detection_results.fire_locations;
      } 
      // Check if fire_locations exist directly in the response data
      else if (response.data.fire_locations) {
        console.log('Found fire_locations directly in response');
        fireLocations = response.data.fire_locations;
      }
      // Check if they're in the top-level detection_results
      else if (response.data.detection_results && response.data.detection_results.fire_locations) {
        console.log('Found fire_locations in detection_results');
        fireLocations = response.data.detection_results.fire_locations;
      }
      
      // Check if we need to extract from ANY targets in the response
      if (fireLocations.length === 0 && response.data.targets) {
        console.log('Searching all targets for fire locations');
        
        // Loop through all targets to find fire locations
        Object.keys(response.data.targets).forEach(tid => {
          Object.keys(response.data.targets[tid]).forEach(nid => {
            if (response.data.targets[tid][nid].detection_results && 
                response.data.targets[tid][nid].detection_results.fire_locations) {
              console.log(`Found fire locations in target ${tid}, notification ${nid}`);
              fireLocations = [...fireLocations, ...response.data.targets[tid][nid].detection_results.fire_locations];
            }
          });
        });
      }
      
      console.log('Fire locations found:', fireLocations);
      
      if (fireLocations && fireLocations.length > 0) {
        console.log('Processing fire locations for markers');
        
        // Extract perimeters from fire locations if they exist
        const perimeters = fireLocations
          .filter(fire => fire && Array.isArray(fire.perimeter) && fire.perimeter.length >= 3)
          .map(fire => {
            console.log('Processing perimeter for fire:', fire);
            return {
              id: fire.fire_id || `fire-${Math.floor(Math.random() * 10000)}`,
              coordinates: fire.perimeter,
              confidence: fire.confidence || 0.7
            };
          });
        
        console.log('Perimeters found:', perimeters);
        if (onAddPerimeters && perimeters.length > 0) {
          console.log('Adding perimeters to map');
          onAddPerimeters(perimeters);
        }
        
        // Convert fire locations to markers for the map
        const markers = fireLocations.map(fire => {
          // Ensure we have a valid fire location object
          if (!fire) {
            console.warn('Invalid fire location object:', fire);
            return null;
          }
          
          console.log('Processing fire:', fire);
          
          // Some fire locations might have different formats, handle all cases
          let lat, lng;
          
          if (Array.isArray(fire.location) && fire.location.length >= 2) {
            // Standard format [lat, lng]
            lat = fire.location[0];
            lng = fire.location[1];
            console.log('Using array location format:', fire.location);
          } else if (fire.lat !== undefined && fire.lng !== undefined) {
            // Already in {lat, lng} format
            lat = fire.lat;
            lng = fire.lng;
            console.log('Using lat/lng object format:', { lat, lng });
          } else if (fire.latitude !== undefined && fire.longitude !== undefined) {
            // {latitude, longitude} format
            lat = fire.latitude;
            lng = fire.longitude;
            console.log('Using latitude/longitude format:', { lat, lng });
          } else {
            console.warn('Could not determine location format for fire:', fire);
            return null;
          }
          
          // Validate the coordinates
          if (typeof lat !== 'number' || typeof lng !== 'number' || 
              isNaN(lat) || isNaN(lng) || 
              lat < -90 || lat > 90 || lng < -180 || lng > 180) {
            console.warn('Invalid coordinates:', { lat, lng });
            return null;
          }
          
          // Generate weather data if not provided
          let weatherData = fire.weather || {
            wind_speed: 5 + Math.random() * 25, // Random wind speed between 5-30 km/h
            wind_direction: Math.round(Math.random() * 360), // Random direction 0-359 degrees
            temperature: 15 + Math.random() * 20, // Random temp between 15-35 C
            humidity: 20 + Math.random() * 60 // Random humidity between 20-80%
          };
          
          // Round values for display
          weatherData.wind_speed = Math.round(weatherData.wind_speed * 10) / 10;
          weatherData.temperature = Math.round(weatherData.temperature * 10) / 10;
          weatherData.humidity = Math.round(weatherData.humidity);
          
          const marker = {
            lat,
            lng,
            confidence: fire.confidence || 0.7, // Default confidence if not provided
            id: fire.fire_id || `fire-${Math.floor(Math.random() * 10000)}`, // Generate ID if not provided
            weather: weatherData,
            detected_at: fire.detected_at || new Date().toISOString()
          };
          
          console.log('Created marker:', marker);
          return marker;
        }).filter(marker => marker !== null); // Remove any invalid markers
        
        console.log('Markers to add:', markers);
        
        if (onAddMarkers && markers.length > 0) {
          console.log('Calling onAddMarkers with markers');
          
          // Add markers one by one with timing to ensure they're processed
          markers.forEach((marker, index) => {
            console.log(`Adding marker ${index}:`, marker);
          });
          
          // Add all markers at once
          onAddMarkers(markers);
          
        } else {
          console.warn('Not calling onAddMarkers:', { 
            onAddMarkers: !!onAddMarkers, 
            markerLength: markers.length 
          });
        }
      } else {
        console.warn('No fire locations found in response. Response structure:', {
          hasDirectFireLocations: !!response.data.fire_locations,
          hasDetectionResults: !!response.data.detection_results,
          hasNestedDetectionResults: response.data.detection_results && !!response.data.detection_results.fire_locations,
          hasTargets: !!response.data.targets,
          hasTargetId: response.data.targets && !!response.data.targets[targetId],
          hasNotificationNum: response.data.targets && 
                             response.data.targets[targetId] && 
                             !!response.data.targets[targetId][notificationNum],
          hasTargetDetectionResults: response.data.targets && 
                              response.data.targets[targetId] && 
                              response.data.targets[targetId][notificationNum] && 
                              !!response.data.targets[targetId][notificationNum].detection_results,
          hasTargetFireLocations: response.data.targets && 
                           response.data.targets[targetId] && 
                           response.data.targets[targetId][notificationNum] && 
                           response.data.targets[targetId][notificationNum].detection_results && 
                           !!response.data.targets[targetId][notificationNum].detection_results.fire_locations
        });
      }
    } catch (err) {
      console.error('Error running simulation:', err);
      setError('Failed to run simulation');
    } finally {
      setLoading(false);
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="panel-content">
        <h3>URL Scanner</h3>
        <p>Please log in to access the scanner controls.</p>
      </div>
    );
  }

  return (
    <div className="panel-content">
      <h3>URL Scanner</h3>
      {error && <div className="error-message">{error}</div>}
      
      {/* Debug props info */}
      {process.env.NODE_ENV === 'development' && (
        <div style={{ fontSize: '12px', color: '#888', marginBottom: '10px' }}>
          onAddMarkers prop: {onAddMarkers ? 'Available ✅' : 'Missing ❌'}<br />
          onAddPerimeters prop: {onAddPerimeters ? 'Available ✅' : 'Missing ❌'}
        </div>
      )}
      
      <form onSubmit={handleRegisterUrl}>
        <label>
          URL to Monitor:
          <input
            type="url"
            value={scanUrl}
            onChange={(e) => setScanUrl(e.target.value)}
            placeholder="https://example.com/data.geojson"
            required
          />
        </label>
        <button type="submit" disabled={loading}>Register URL</button>
      </form>

      <form onSubmit={handleSetInterval}>
        <label>
          Scan Interval (seconds):
          <input
            type="number"
            value={interval}
            onChange={(e) => setInterval(parseInt(e.target.value))}
            min="1"
            required
          />
        </label>
        <button type="submit" disabled={loading}>Set Interval</button>
      </form>

      <form onSubmit={handleSimulation}>
        <h4>Test System</h4>
        <label>
          Coordinates:
          <textarea
            value={coordsStr}
            onChange={(e) => setCoordsStr(e.target.value)}
            placeholder="[[47.966461017073314, -124.28956657078744],[46.15177698853681, -115.64566246825329], [32.5701428063953, -107.99629909502727], [32.78787082689376, -123.99714132018126]]"
            rows="4"
            className="coordinates-input"
          />
        </label>
        <div className="time-inputs">
          <label>
            Start Time:
            <input
              type="datetime-local"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              required
            />
          </label>
          <label>
            End Time:
            <input
              type="datetime-local"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              required
            />
          </label>
        </div>
        <button type="submit" disabled={loading}>Start Simulation</button>
      </form>

      <div className="scanner-actions">
        <button 
          onClick={handleStartScan} 
          disabled={loading || (status && status.is_scanning)}
        >
          Start Scanning
        </button>
        <button 
          onClick={handleStopScan} 
          disabled={loading || (status && !status.is_scanning)}
        >
          Stop Scanning
        </button>
      </div>

      {status && (
        <div className="status-info">
          <h4>Current Status</h4>
          <p>URL: {status.scan_url || 'None'}</p>
          <p>Scanning: {status.is_scanning ? 'Active' : 'Inactive'}</p>
          <p>Interval: {status.scan_interval} seconds</p>
          <p>Targets Count: {status.targets_count}</p>
        </div>
      )}
    </div>
  );
};

export default ScannerControls;