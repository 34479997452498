import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../services/api';
import { useAuth } from '../contexts/AuthContext';

const NotificationCenter = ({ fireData, onClose = () => {} }) => {
  const [phoneNumbers, setPhoneNumbers] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { isAuthenticated } = useAuth();

  // Estimated growth rate based on wind velocity
  const calculateGrowthRate = () => {
    if (!fireData || !fireData.weather) return "Unknown";
    
    const { wind_speed, wind_direction } = fireData.weather;
    
    if (!wind_speed) return "Unknown";
    
    // Simple formula: Growth rate proportional to wind speed
    // Low: 0-10 km/h, Medium: 10-20 km/h, High: >20 km/h
    if (wind_speed < 10) {
      return "Low";
    } else if (wind_speed < 20) {
      return "Medium";
    } else {
      return "High";
    }
  };

  const formatMessage = () => {
    const growthRate = calculateGrowthRate();
    const lat = fireData?.lat.toFixed(5) || "Unknown";
    const lng = fireData?.lng.toFixed(5) || "Unknown";
    const confidence = fireData?.confidence ? `${(fireData.confidence * 100).toFixed(1)}%` : "Unknown";
    
    return `ALERT: Fire detected at location [${lat}, ${lng}] with ${confidence} confidence. Estimated growth rate: ${growthRate}`;
  };

  const sendWhatsAppNotification = async () => {
    if (!phoneNumbers.trim()) {
      setMessage("Please enter at least one phone number");
      return;
    }

    setIsLoading(true);
    setMessage("");

    try {
      // Split and clean phone numbers
      const numbers = phoneNumbers.split(',').map(num => num.trim());

      // Call the backend API to send WhatsApp notifications
      const response = await axios.post(`${API_BASE_URL}/api/notifications/whatsapp`, {
        phone_numbers: numbers,
        message: formatMessage(),
        fire_data: {
          location: [fireData.lat, fireData.lng],
          confidence: fireData.confidence,
          growth_rate: calculateGrowthRate(),
          detected_at: new Date().toISOString()
        }
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      setMessage("Notifications sent successfully via WhatsApp");
    } catch (error) {
      console.error("Failed to send WhatsApp notifications:", error);
      setMessage(`Failed to send notifications: ${error.response?.data?.detail || error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const sendEverbridgeNotification = async () => {
    setIsLoading(true);
    setMessage("");

    try {
      // Call the backend API to send Everbridge notifications
      const response = await axios.post(`${API_BASE_URL}/api/notifications/everbridge`, {
        message: formatMessage(),
        fire_data: {
          location: [fireData.lat, fireData.lng],
          confidence: fireData.confidence,
          growth_rate: calculateGrowthRate(),
          detected_at: new Date().toISOString()
        }
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      setMessage("Notifications sent successfully via Everbridge");
    } catch (error) {
      console.error("Failed to send Everbridge notifications:", error);
      setMessage(`Failed to send notifications: ${error.response?.data?.detail || error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="notification-center">
        <h3>Notification Center</h3>
        <p>Please log in to access notification features.</p>
        <button onClick={onClose} className="close-button">Close</button>
      </div>
    );
  }

  return (
    <div className="notification-center">
      <h3>Notification Center</h3>
      
      {!fireData ? (
        <div className="no-fire-selected">
          <p>Please select a fire marker on the map to send notifications about a specific fire location.</p>
        </div>
      ) : (
        <>
          <div className="fire-info-section">
            <h4>Fire Information</h4>
            <p><strong>Location:</strong> [{fireData?.lat.toFixed(5)}, {fireData?.lng.toFixed(5)}]</p>
            <p><strong>Confidence:</strong> {fireData?.confidence ? `${(fireData.confidence * 100).toFixed(1)}%` : "Unknown"}</p>
            <p><strong>Estimated Growth Rate:</strong> {calculateGrowthRate()}</p>
          </div>
          
          <div className="notification-section">
            <h4>Send Notifications</h4>
            
            <div className="whatsapp-section">
              <h5>WhatsApp Notification</h5>
              <label>
                Phone Numbers (comma separated):
                <textarea 
                  value={phoneNumbers}
                  onChange={(e) => setPhoneNumbers(e.target.value)}
                  placeholder="+1234567890, +0987654321"
                  rows="3"
                />
              </label>
              <button 
                onClick={sendWhatsAppNotification} 
                disabled={isLoading}
                className="whatsapp-button"
              >
                {isLoading ? "Sending..." : "Send WhatsApp Notification"}
              </button>
            </div>
            
            <div className="everbridge-section">
              <h5>Everbridge Notification</h5>
              <p>Send alert to all registered emergency contacts in the Everbridge system.</p>
              <button 
                onClick={sendEverbridgeNotification} 
                disabled={isLoading}
                className="everbridge-button"
              >
                {isLoading ? "Sending..." : "Send Everbridge Alert"}
              </button>
            </div>
          </div>
          
          {message && (
            <div className={message.includes("Failed") ? "error-message" : "success-message"}>
              {message}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NotificationCenter;