import React, { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

const WindLayer = ({ active }) => {
  const map = useMap();
  const windyRef = useRef(null);
  const canvasRef = useRef(null);
  const dataRef = useRef(null);

  useEffect(() => {
    // Load wind data JSON
    const loadWindData = async () => {
      try {
        const response = await fetch('/windData.json');
        if (!response.ok) {
          throw new Error(`Failed to load wind data: ${response.status}`);
        }
        const data = await response.json();
        dataRef.current = data;
        initializeWindLayer();
      } catch (err) {
        console.error('Error loading wind data:', err);
      }
    };

    if (active && !dataRef.current) {
      loadWindData();
    } else if (active && dataRef.current) {
      initializeWindLayer();
    } else if (!active && windyRef.current) {
      cleanupWindLayer();
    }

    return () => {
      cleanupWindLayer();
    };
  }, [active, map]);

  const initializeWindLayer = () => {
    if (!map || !dataRef.current || !window.Windy) return;

    // Clean up any existing instance
    cleanupWindLayer();

    // Create canvas overlay
    const canvas = document.createElement('canvas');
    const bounds = map.getBounds();
    const size = map.getSize();

    canvas.width = size.x;
    canvas.height = size.y;
    canvas.style.position = 'absolute';
    canvas.style.pointerEvents = 'none';
    
    // Add canvas as a custom overlay
    const canvasLayer = L.layerGroup().addTo(map);
    const CanvasOverlay = L.Layer.extend({
      onAdd: function() {
        map.getPanes().overlayPane.appendChild(canvas);
      },
      onRemove: function() {
        if (canvas.parentNode) {
          canvas.parentNode.removeChild(canvas);
        }
      },
      draw: function() {
        const mapBounds = map.getBounds();
        const mapSize = map.getSize();
        
        // Update canvas size if map size changed
        if (canvas.width !== mapSize.x || canvas.height !== mapSize.y) {
          canvas.width = mapSize.x;
          canvas.height = mapSize.y;
        }
        
        // Clear canvas
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        
        // Initialize wind layer if not already done
        if (!windyRef.current && dataRef.current) {
          const sw = mapBounds.getSouthWest();
          const ne = mapBounds.getNorthEast();
          
          windyRef.current = new window.Windy({
            canvas: canvas,
            data: dataRef.current
          });
          
          // Start the wind animation
          windyRef.current.start(
            [
              [0, 0],
              [canvas.width, canvas.height]
            ],
            canvas.width,
            canvas.height,
            [
              [sw.lng, sw.lat],
              [ne.lng, ne.lat]
            ]
          );
        }
      }
    });

    canvasRef.current = new CanvasOverlay().addTo(map);
    
    // Redraw when map moves
    const updateWind = () => {
      if (windyRef.current) {
        windyRef.current.stop();
        windyRef.current = null;
      }
      
      if (canvasRef.current) {
        canvasRef.current.draw();
      }
    };
    
    map.on('moveend', updateWind);
    map.on('resize', updateWind);
    
    // Initial draw
    canvasRef.current.draw();
  };

  const cleanupWindLayer = () => {
    if (windyRef.current) {
      windyRef.current.stop();
      windyRef.current = null;
    }
    
    if (canvasRef.current) {
      map.removeLayer(canvasRef.current);
      canvasRef.current = null;
    }
  };

  return null; // This component doesn't render anything directly
};

export default WindLayer;