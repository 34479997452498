import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

const Login = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const { login, register, error, isAuthenticated, currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [localError, setLocalError] = useState(null);

  // Check if user becomes authenticated during login process
  useEffect(() => {
    if (isAuthenticated && currentUser) {
      console.log('User is authenticated:', currentUser);
      // If authenticated and the panel should close, close it
      if (onClose) {
        onClose();
      }
    }
  }, [isAuthenticated, currentUser, onClose]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLocalError(null);
    
    console.log('Submitting login form with email:', email);
    
    try {
      let success;
      if (isLogin) {
        
        success = await login(email, password);
        console.log('Login result:', success);
      } else {
        success = await register(email, password);
      }
      
      if (success && onClose) {
        onClose();
      }
    } catch (err) {
      console.error('Login component error:', err);
      setLocalError('Failed to authenticate. Please check your credentials and try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="panel-content">
      <h3>{isLogin ? 'Login' : 'Register'}</h3>
      {(error || localError) && <div className="error-message">{error || localError}</div>}
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Processing...' : (isLogin ? 'Login' : 'Register')}
        </button>
      </form>
      <p>
        {isLogin ? "Don't have an account? " : "Already have an account? "}
        <button 
          className="link-button"
          onClick={() => setIsLogin(!isLogin)}
        >
          {isLogin ? 'Register' : 'Login'}
        </button>
      </p>
    </div>
  );
};

export default Login;